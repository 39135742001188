export default {
  metaTitle: (page) => {
    const title = page.acf.pagetitle_seo ||
      page.acf.titre_seo ||
      page.title.rendered
    return title.replace(/(<([^>]+)>)/ig,"").replace(/\&rsquo;/g, "")
  },
  metaDescription: (page) => {
    const descr = page.acf.description_seo ||
      page.content.rendered
    return descr.replace(/(<([^>]+)>)/ig,"").replace(/\&rsquo;/g, "").substring(0, 400)
  },
  ogTitle: (page) => {
    const title = page.acf.og_title ||
      page.title.rendered
    return title.replace(/(<([^>]+)>)/ig,"").replace(/\&rsquo;/g, "")
  },
  ogDescription: (page) => {
    const descr = page.acf.og_description ||
      page.acf.description_seo ||
      page.content.rendered
    return descr.replace(/(<([^>]+)>)/ig,"").replace(/\&rsquo;/g, "").substring(0, 400)
  }
}
