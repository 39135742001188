import seo from '@/utils/head'

export const asyncMixin = {
}



export const headPageMixin = {
  head(){
    let imgUrl = ''
    let imgW = ''
    let imgH = ''
    if(this.currentPage.post_images){
      imgUrl = this.currentPage.post_images.sizes.og_img;
      imgW = this.currentPage.post_images.sizes['og_img-width']
      imgH = this.currentPage.post_images.sizes['og_img-height']
    }
    return {
      title: seo.metaTitle(this.currentPage),
      // titleTemplate: "%s | Atelier IDEA, architecture d'intérieur à Saint Jean de Luz",
      titleTemplate: "%s",
      meta: [
        { hid: 'description', name: 'description', content: seo.metaDescription(this.currentPage) },
        { hid: 'og:url', property: 'og:url', content: this.$config.appUrl + '/' + this.currentPage.slug },
        { hid: 'og:type', property: 'og:type', content: 'article'},
        { hid: 'og:name', property: 'og:site_name', content: 'Atelier IDEA'},
        { hid: 'og:title', property: 'og:title', content: seo.ogTitle(this.currentPage)},
        { hid: 'og:description', property: 'og:description', content: seo.ogDescription(this.currentPage) },
        { hid: 'og:img', property: 'og:image', content: imgUrl || '' },
        { hid: 'og:imgwidth', property: 'og:image:width', content: imgW || '' },
        { hid: 'og:imgheight', property: 'og:image:height', content: imgH  || ''},
      ]
    }
  }
}

export const headRealMixin = {
  head(){
    return {
      title: this.project.title.rendered.replace(/(<([^>]+)>)/ig,"").replace(/\&rsquo;/g, "'"),
      titleTemplate: "%s | Atelier IDEA, architecture d'intérieur à Saint Jean de Luz",
      meta: [
        { hid: 'description', name: 'description', content: this.project.content.rendered.replace(/(<([^>]+)>)|(&lt;([^>]+)&gt;)/ig,"").substring(0, 300) },
        { hid: 'og:url', property: 'og:url', content: process.env.appUrl + '/nos-realisations/' + this.project.slug},
        { hid: 'og:type', property: 'og:type', content: 'article'},
        { hid: 'og:name', property: 'og:site_name', content: 'Atelier IDEA'},
        { hid: 'og:title', property: 'og:title', content: this.project.title.rendered.replace(/\&rsquo;/g, "'") + " | Atelier IDEA, architecture d'intérieur à Saint Jean de Luz" },
        { hid: 'og:description', property: 'og:description', content: this.project.content.rendered.replace(/(<([^>]+)>)|(&lt;([^>]+)&gt;)/ig,"").substring(0, 300)},
        { hid: 'og:img', property: 'og:image', content: this.project.post_images.sizes.og_img.source_url },
        { hid: 'og:imgwidth', property: 'og:image:width', content: this.project.post_images.sizes.og_img.width },
        { hid: 'og:imgheight', property: 'og:image:height', content: this.project.post_images.sizes.og_img.height },
      ]
    }
  }
}
