<template>
  <v-container class="page home" fluid pa-0>
    <v-container fluid tag="section" class="section intro grey lighten-3 mt-5 px-0 py-5">
      <v-container class="sheet">
        <v-layout>
          <v-flex>
            <h2 class="sectionTitle">
              <span>Atelier IdeA</span>
              Architecture d'intérieur
            </h2>
            <div class="content text-center" v-html="currentPage.content.rendered"></div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-container>
    <v-container fluid tag="section" class="section realisations px-0 pt-3 pb-5 mb-5">
      <v-container grid-list-xl class="sheet">
        <cards-list :projects="projectsToShow" />
      </v-container>
    </v-container>
    <v-img fluid tag="section" class="section pos-relative" :src="currentPage.acf.bottom_parallax_img.sizes.xxlarge" height="40vh">
      <v-overlay opacity="0.45" absolute>
        <div class="container sheet py-5 text-center fill-height">
          <v-row align="center">
            <v-col cols="12">
              <h2 class="center sectionTitle py-5">
                <router-link class="white--text fatter-text" :to="{name:'la-tribu'}"><span>Découvrir</span>L'équipe Idea</router-link>
              </h2>
            </v-col>
          </v-row>
        </div>
      </v-overlay>
    </v-img>
  </v-container>
</template>
<script>
import {asyncMixin,headPageMixin} from '../mixins'
export default {
  name:'Home',
  components: {
    cardsList: () => import('@/components/lists/cardsListHome.vue')
  },
  props: ['navFixed'],
  mixins:[asyncMixin,headPageMixin],
  async fetch () {
    this.projects = await this.$axios.get('/api2/realisations', { params: {per_page:8, show_in_home:1 } }).then(res => res.data)
  },
  data: () => ({
    projects: []
  }),
  computed:{
    currentPage(){
      return this.$store.state.pagesList.find(o => o.slug == this.$route.name) || {}
    },
    projectsToShow(){
      return this.projects.filter(p => p.acf && p.acf.show_in_home)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if(from && from.name){
        vm.$nextTick(()=>{
          vm.$vuetify.goTo('.v-toolbar')
        })
      }
    })
  }
}

</script>
